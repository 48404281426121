.Wrapper {
    display: flex;
    justify-content: space-between;
}
.Main, .SideBar {
    padding: 15px;
}
.Main {
    width: 60%;
    height: 150vh;
}
.MainBody {
    text-align: left;
    font-size: 20pt;
}
.AboutBody {
    text-align: left;
}
.SideBar {
    width: 25%;
    min-width: 250px;
    height: 30vh;
    position: sticky;
    top: 0;
}
@media (max-width: 1150px) {
    .SideBar{display: none;}
    .Main{width: 100%;}
}
@media (max-width: 825px) {
    .shrink{display: none;}
}
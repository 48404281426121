body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.User {
	margin-top: 20px;
	text-align: left;
}
i {
	border: solid black;
	border-width: 0 3px 3px 0;
	display: inline-block;
	padding: 3px;
}
  
.right {
	transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
}
  
.left {
	transform: rotate(135deg);
	-webkit-transform: rotate(135deg);
}
  
.up {
	transform: rotate(-135deg);
	-webkit-transform: rotate(-135deg);
}
  
.down {
	transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
}
table{
    text-align: center;
    margin-right: auto;
    margin-left: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    border: 2px solid #c5d4ff;
}
th, td {
    text-align: left;
    padding: 10px;
    font-size: 12pt;
    padding-left: 30px;
    padding-right: 30px;
}
th {
    background-color: #e3e1fa;
    border-bottom: 2px solid #c5d4ff;
}

tr:nth-child(odd) {
    background-color: #efedfa;
}

tr:nth-child(even) {
    background-color: #e3e1fa;
}

.TableHeader {
    text-align: center;
    font-size: 10pt;
}
.List {
	margin-left: 20px;
	margin-top: 20px;
	text-align: left;
}
.App {
  text-align: center;
  -webkit-align-content: center;
          align-content: center;
  padding: 20px;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 9999;
}
.User {
	margin-left: 20px;
	margin-top: 20px;
	text-align: left;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
}
.UserMain {
	margin-left: 20px;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
}

.UserSideBar {
	background-color: black;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 30vh;
    position: -webkit-sticky;
    position: sticky;
	top: 100px;
	margin-bottom: 100px;
}
.card-body {
	text-align: center;
}
.img-aspect {
	margin: auto;
	width: auto;
	height: auto;
	max-width: 256px;
	max-height: 256px;
}
.img-holder {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	width: 256px;
	height: 256px;
}
.range-holder {
	width: 500px;
	margin: auto;
}

.Tournament {
	margin-left: 20px;
	margin-top: 20px;
	text-align: left;
}
.Tournament {
	margin-left: 20px;
	margin-top: 20px;
	text-align: left;
}
.Table {
	margin-left: 5px;
	margin-right: 5px;
	text-align: center;
}
.WholePage {
    overflow: auto;
    height: auto;
    padding-top: 20px;
    background-color: #f8f9fa;
    max-width: 1400px;
    margin: auto;
}
.TopSide {
    background-color: inherit;
}
.BottomSide {
    padding: 20px;
    background-color: inherit;
    text-align: left;
}
.LeftSide {
    width: 65%;
    float: left;
    text-align: right;
    background-color: inherit;
    padding: 20px;
    margin-top: 0px;
    max-width: 1000px;
    margin: auto;
}
.RightSide {
    width: 35%;
    float: left;
    text-align: left;
    background-color: inherit;
    padding: 20px;
    margin-top: 0px;
    max-width: 490px;
}
.top-table{
    text-align: center;
    margin-right: auto;
    margin-left: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    border: 2px solid lightgray;
}
.top-th {
    text-align: center;
    padding: 10px;
    font-size: 12pt;
    padding-left: 30px;
    padding-right: 30px;
    background-color: gainsboro;
    border:2px solid lightgray;
}
.top-td {
    border-bottom: 2px solid lightgray;
    text-align: center;
    padding: 10px;
    font-size: 12pt;
    padding-left: 10px;
    padding-right: 10px;
    background-color: rgb(250, 250, 250);
}
.top-name {
    text-align: left;
}
.top-imageholder {
    padding-left: 10px;
    padding-right: 10px;
}
.top-image {
    width: 100px;
    height: 100px;
}
.table-icon {
    width: 1rem;
    height: 1rem;
}
.rank-icon {
    width: 1.7rem;
    height: 1.7rem;
}
@media (max-width: 1050px) {
    .main-right{display: none;}
    .main-left{width: 100%; text-align: center;}
}
@media (max-width: 600px) {
    h1{font-size: 2rem;}
    h4{font-size: 1rem;}
    .RightSide{display: none;}
    .LeftSide{width: 100%; padding-left: 10px; padding-right: 10px;}
    .BottomSide{padding-left: 0px; padding-right: 0px;}
    .card-subtitle{display: none;}
    .card-body{padding: 5px;}
    .lg-view{display: none;}
}
@media (min-width: 601px) {
    .sm-view{display: none;}
}
.Wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}
.Main, .SideBar {
    padding: 15px;
}
.Main {
    width: 60%;
    height: 150vh;
}
.MainBody {
    text-align: left;
    font-size: 20pt;
}
.AboutBody {
    text-align: left;
}
.SideBar {
    width: 25%;
    min-width: 250px;
    height: 30vh;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}
@media (max-width: 1150px) {
    .SideBar{display: none;}
    .Main{width: 100%;}
}
@media (max-width: 825px) {
    .shrink{display: none;}
}

.User {
	margin-left: 20px;
	margin-top: 20px;
	text-align: left;
	width: fit-content;
}
.UserMain {
	margin-left: 20px;
	width: fit-content;
}

.UserSideBar {
	background-color: black;
    width: fit-content;
    height: 30vh;
    position: sticky;
	top: 100px;
	margin-bottom: 100px;
}
.card-body {
	text-align: center;
}
.img-aspect {
	margin: auto;
	width: auto;
	height: auto;
	max-width: 256px;
	max-height: 256px;
}
.img-holder {
	display: flex;
	justify-content: center;
	width: 256px;
	height: 256px;
}
.range-holder {
	width: 500px;
	margin: auto;
}

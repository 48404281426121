.WholePage {
    overflow: auto;
    height: auto;
    padding-top: 20px;
    background-color: #f8f9fa;
    max-width: 1400px;
    margin: auto;
}
.TopSide {
    background-color: inherit;
}
.BottomSide {
    padding: 20px;
    background-color: inherit;
    text-align: left;
}
.LeftSide {
    width: 65%;
    float: left;
    text-align: right;
    background-color: inherit;
    padding: 20px;
    margin-top: 0px;
    max-width: 1000px;
    margin: auto;
}
.RightSide {
    width: 35%;
    float: left;
    text-align: left;
    background-color: inherit;
    padding: 20px;
    margin-top: 0px;
    max-width: 490px;
}
.top-table{
    text-align: center;
    margin-right: auto;
    margin-left: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    border: 2px solid lightgray;
}
.top-th {
    text-align: center;
    padding: 10px;
    font-size: 12pt;
    padding-left: 30px;
    padding-right: 30px;
    background-color: gainsboro;
    border:2px solid lightgray;
}
.top-td {
    border-bottom: 2px solid lightgray;
    text-align: center;
    padding: 10px;
    font-size: 12pt;
    padding-left: 10px;
    padding-right: 10px;
    background-color: rgb(250, 250, 250);
}
.top-name {
    text-align: left;
}
.top-imageholder {
    padding-left: 10px;
    padding-right: 10px;
}
.top-image {
    width: 100px;
    height: 100px;
}
.table-icon {
    width: 1rem;
    height: 1rem;
}
.rank-icon {
    width: 1.7rem;
    height: 1.7rem;
}
@media (max-width: 1050px) {
    .main-right{display: none;}
    .main-left{width: 100%; text-align: center;}
}
@media (max-width: 600px) {
    h1{font-size: 2rem;}
    h4{font-size: 1rem;}
    .RightSide{display: none;}
    .LeftSide{width: 100%; padding-left: 10px; padding-right: 10px;}
    .BottomSide{padding-left: 0px; padding-right: 0px;}
    .card-subtitle{display: none;}
    .card-body{padding: 5px;}
    .lg-view{display: none;}
}
@media (min-width: 601px) {
    .sm-view{display: none;}
}